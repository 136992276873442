import { Map as hashMap } from 'immutable';
import { createSelector } from 'reselect';

const noopFn = () => {};

export type ModalCode =
  | 'archived_resource'
  | 'chapter-rating'
  | 'course_notes'
  | 'course_outline'
  | 'dashboard_survey'
  | 'download_datasets'
  | 'feedback_reporter'
  | 'guides'
  | 'iframe'
  | 'interact_with_floating_pane'
  | 'issue_reporter'
  | 'keyboard_shortcuts'
  | 'loading_workbooks'
  | 'login'
  | 'ltiFailure'
  | 'mobile-popup'
  | 'reference_material'
  | 'screen_larger'
  | 'show_learn_recap'
  | 'show_or_skip_recap'
  | 'slides'
  | 'video';

export type ModalType = {
  code: ModalCode;
  onExit?: () => void;
};
export const MODAL_TYPE = {
  COURSE_OUTLINE: { code: 'course_outline' },
  KEYBOARD_SHORTCUTS: { code: 'keyboard_shortcuts' },
  ISSUE_REPORTER: { code: 'issue_reporter' },
  IFRAME: { code: 'iframe' },
  FEEDBACK_REPORTER: { code: 'feedback_reporter' },
  LOGIN: { code: 'login', onExit: noopFn },
  SLIDES: { code: 'slides' },
  COURSE_NOTES: { code: 'course_notes' },
  LTI_FAILURE: { code: 'ltiFailure', onExit: noopFn },
  CHAPTER_RATING: { code: 'chapter-rating', onExit: noopFn },
  MOBILE_POPUP: { code: 'mobile-popup' },
  VIDEO: { code: 'video' },
  GUIDES: { code: 'guides' },
  DOWNLOAD_DATASETS: { code: 'download_datasets' },
  SCREEN_LARGER: { code: 'screen_larger' },
  INTERACT_WITH_FLOATING_PANE: { code: 'interact_with_floating_pane' },
  REFERENCE_MATERIAL: { code: 'reference_material' },
  LOADING_WORKBOOKS: { code: 'loading_workbooks' },
  DASHBOARD_SURVEY: { code: 'dashboard_survey' },
  SHOW_LEARN_RECAP: { code: 'show_learn_recap' },
  SHOW_OR_SKIP_RECAP: { code: 'show_or_skip_recap' },
  ARCHIVED_RESOURCE: { code: 'archived_resource' },
} as const;

// Type assertion to make sure the MODAL_TYPE has the right shape without losing string literal information

MODAL_TYPE as { [key: string]: ModalType };

export const MODAL_COMPONENT_TYPE = {
  NO_MODAL: 'NO_MODAL',
  WAFFLES_DIALOG: 'WAFFLES_DIALOG',
} as const;
export type ModalComponentType =
  (typeof MODAL_COMPONENT_TYPE)[keyof typeof MODAL_COMPONENT_TYPE];

export const selectSettings = (state: any) =>
  state.get('settings') || hashMap();
export const selectShortcutsUsage = (state: any) =>
  selectSettings(state).get('shortcutsUsage', 0);
export const selectShouldCloseShortcutFrame = (state: any) =>
  selectSettings(state).get('shouldCloseShortcutFrame', false);
export const selectModalSettings = (state: any) =>
  selectSettings(state).get('modal') || hashMap();
export const selectIsExerciseActive = (state: any) =>
  selectSettings(state).get('isExerciseActive', true);
export const selectShouldStopBackendManager = (state: any) =>
  selectSettings(state).get('shouldStopBackendManager') || false;
export const selectUiTheme = createSelector(selectSettings, (settings) =>
  settings.get('uiTheme'),
);
export const selectIsDarkTheme = createSelector(
  selectUiTheme,
  (uiTheme) => uiTheme === 'DARK',
);
export const selectIsLightTheme = createSelector(
  selectIsDarkTheme,
  (isDarkTheme) => !isDarkTheme,
);
export const selectFeedbackRatingStatus = createSelector(
  selectSettings,
  (settings) => settings.get('feedbackRatingStatus'),
);
export const selectMobileView = createSelector(selectSettings, (settings) =>
  settings.get('mobileView'),
);
