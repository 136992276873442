import ApiClient from '../../helpers/ApiClient';
import { getMainAppApiClient } from '../../helpers/getClients';
import type { Thunk } from '../../interfaces/State';
import * as selectors from '../selectors';
import { selectStreakDeadline, selectStreakInfoReady } from '../selectors';

import type { StreakInfoResponse } from './boot';

export const STREAK_INFO_UPDATED = 'streaks/INFO_UPDATED';
export type StreakInfoUpdatedAction = {
  data?: StreakInfoResponse;
  type: typeof STREAK_INFO_UPDATED;
};
export const streakInfoUpdated = (
  data?: StreakInfoResponse,
): StreakInfoUpdatedAction => ({
  type: STREAK_INFO_UPDATED,
  data,
});

export const refreshDailyStreak = (): Thunk => (dispatch, getState) => {
  const state = getState();

  const streakInfoReady = selectStreakInfoReady(state);

  if (!streakInfoReady) {
    return dispatch(streakInfoUpdated());
  }

  const streakDeadline = selectStreakDeadline(state);

  if (!streakDeadline || Date.now() < streakDeadline) {
    return dispatch(streakInfoUpdated());
  }

  return getMainAppApiClient()
    .execute(ApiClient.endpoint.getStreak())
    .toPromise()
    .then(({ body }: { body?: StreakInfoResponse }) => {
      return dispatch(streakInfoUpdated(body));
    })
    .catch((err: Error) => {
      // eslint-disable-next-line
      console.warn(
        'Error refreshing streak state, delaying until next attempt',
        err,
      );

      // Queue the next update anyway and try again later
      return dispatch(streakInfoUpdated());
    });
};

export const SHOW_DAILY_STREAK_SCREEN = 'streaks/SHOW_DAILY_STREAK_SCREEN';
export type ShowDailyStreakScreenAction = {
  type: typeof SHOW_DAILY_STREAK_SCREEN;
};
export const showDailyStreakScreen = (): ShowDailyStreakScreenAction => ({
  type: SHOW_DAILY_STREAK_SCREEN,
});

export const HIDE_DAILY_STREAK_SCREEN = 'streaks/HIDE_DAILY_STREAK_SCREEN';
export type HideDailyStreakScreenAction = {
  type: typeof HIDE_DAILY_STREAK_SCREEN;
};
export const hideDailyStreakScreen = (): HideDailyStreakScreenAction => ({
  type: HIDE_DAILY_STREAK_SCREEN,
});

export const checkDailyStreak = (): Thunk => (dispatch, getState) => {
  const state = getState();

  const hasSeenCampusTour = selectors.selectHasSeenCampusTour(state);
  const streakInfoReady = selectors.selectStreakInfoReady(state);
  const streakDailyGoalMetInSession =
    selectors.selectStreakDailyGoalMetInSession(state);

  if (!streakInfoReady || !streakDailyGoalMetInSession || !hasSeenCampusTour) {
    return;
  }

  dispatch(showDailyStreakScreen());
};

export type StreakActions =
  | HideDailyStreakScreenAction
  | ShowDailyStreakScreenAction
  | StreakInfoUpdatedAction;
