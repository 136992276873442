import { generatePrediction } from '../../helpers/ai/api';
import type { Dispatch, State } from '../../interfaces/State';
import unreachable from '../../interfaces/unreachable';
import type {
  CodeExplanation,
  CodeExplanationInput,
  GptStreamType,
  Prediction,
} from '../reducers/codeExplanation';

export const SET_CODE_EXPLANATION = 'SET_CODE_EXPLANATION';
export type SetCodeExplanation = {
  data: CodeExplanation;
  type: typeof SET_CODE_EXPLANATION;
};
export const setCodeExplanation = ({
  data,
}: Omit<SetCodeExplanation, 'type'>) =>
  ({
    type: SET_CODE_EXPLANATION,
    data,
  } as const);

export const SET_CODE_EXPLANATION_TYPE = 'SET_CODE_EXPLANATION_TYPE';
export type SetCodeExplanationState = {
  codeExplanationType: CodeExplanation['type'];
  type: typeof SET_CODE_EXPLANATION_TYPE;
};
export const setCodeExplanationType = ({
  codeExplanationType,
}: Omit<SetCodeExplanationState, 'type'>) =>
  ({
    type: SET_CODE_EXPLANATION_TYPE,
    codeExplanationType,
  } as const);

export const STREAM_CODE_EXPLANATION = 'STREAM_CODE_EXPLANATION';
export type StreamCodeExplanation = {
  partialCompletion: string;
  type: typeof STREAM_CODE_EXPLANATION;
};
export const streamCodeExplanation = ({
  partialCompletion,
}: Omit<StreamCodeExplanation, 'type'>) =>
  ({
    type: STREAM_CODE_EXPLANATION,
    partialCompletion,
  } as const);

export const getCodeExplanation =
  ({
    input,
    modelTag,
    trigger,
  }: {
    input: CodeExplanationInput;
    modelTag: string;
    trigger: { _tag: 'campusUserExplanation'; exerciseId: number };
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(setCodeExplanation({ data: { type: 'loading' } }));
    try {
      const response = await generatePrediction({
        body: {
          input,
          trigger,
          shouldStream: false,
          useCache: true,
        },
        params: {
          modelTag,
        },
      });
      if (response.ok) {
        const prediction: Prediction = await response.json();
        dispatch(
          setCodeExplanation({
            data: {
              prediction,
              type: 'success',
            },
          }),
        );
      } else {
        dispatch(
          setCodeExplanation({
            data: {
              type: 'error',
            },
          }),
        );
      }
    } catch (error) {
      dispatch(setCodeExplanation({ data: { type: 'error' } }));
    }
  };

export const streamCodeExplanationAction =
  ({
    input,
    modelTag,
    trigger,
  }: {
    input: CodeExplanationInput;
    modelTag: string;
    trigger: { _tag: 'campusUserExplanation'; exerciseId: number };
  }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    dispatch(setCodeExplanationType({ codeExplanationType: 'loading' }));
    const decoder = new TextDecoder('utf-8');
    try {
      const response = await generatePrediction({
        body: {
          input,
          trigger,
          shouldStream: true,
          useCache: true,
          simulateStreamDelay: 30,
        },
        params: {
          modelTag,
        },
      });
      if (response.ok && response.body) {
        const reader = response.body.getReader();
        let errored = false;

        reader.read().then(function processText({ done, value }) {
          // If the user has already left the page, cancel the stream
          const codeExplanation = getState().get('codeExplanation');
          if (codeExplanation.codeExplanation.type === 'initial') {
            return;
          }

          if (done) {
            if (!errored) {
              dispatch(
                setCodeExplanationType({ codeExplanationType: 'success' }),
              );
            }
            return;
          }

          if (value) {
            const decoded = decoder.decode(value);
            decoded.split('\n').forEach(async (line) => {
              if (line.trim() !== '') {
                try {
                  const gptStream = JSON.parse(line) as GptStreamType;
                  switch (gptStream.type) {
                    case 'gpt-error':
                      dispatch(setError);
                      errored = true;
                      break;
                    case 'gpt-id':
                      dispatch(
                        setCodeExplanation({
                          data: {
                            type: 'streaming',
                            prediction: {
                              id: gptStream.id,
                              completion: '',
                            },
                          },
                        }),
                      );
                      break;
                    case 'gpt-partial-answer':
                      dispatch(
                        streamCodeExplanation({
                          partialCompletion: gptStream.choices[0].text,
                        }),
                      );
                      break;
                    default:
                      unreachable(gptStream);
                  }
                } catch (error) {
                  dispatch(setError);
                  errored = true;
                }
              }
            });
          }

          reader.read().then(processText);
        });
      } else {
        dispatch(setError);
      }
    } catch (error) {
      dispatch(setError);
    }
  };

const setError = setCodeExplanation({ data: { type: 'error' } });

export type CodeExplanationActions =
  | SetCodeExplanation
  | SetCodeExplanationState
  | StreamCodeExplanation;
