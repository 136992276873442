import raven from 'raven-js';

import type {
  ContentAuthorizationClient,
  ContentAuthorizationDenyReason,
  ContentAuthorizationErrorResult,
} from '../../helpers/ContentAuthorizationClient/ContentAuthorizationClient';
import { ContentAuthorizationAccess } from '../../helpers/ContentAuthorizationClient/ContentAuthorizationClient';
import type { Dispatch } from '../../interfaces/State';
import unreachable from '../../interfaces/unreachable';

// eslint-disable-next-line no-shadow
export enum ContentAuthorizationStatus {
  denied = 'denied',
  granted = 'granted',
  loading = 'loading',
}

export const CONTENT_AUTHORIZATION_STATUS_UPDATED =
  'contentAuthorization/CONTENT_AUTHORIZATION_STATUS_UPDATED';

type ContentAuthorizationStatusLoadingAction = {
  chapterId: number;
  status: ContentAuthorizationStatus.loading;
  type: typeof CONTENT_AUTHORIZATION_STATUS_UPDATED;
};

type ContentAuthorizationStatusGrantedAction = {
  chapterId: number;
  debug: any;
  status: ContentAuthorizationStatus.granted;
  type: typeof CONTENT_AUTHORIZATION_STATUS_UPDATED;
};

type ContentAuthorizationStatusDeniedAction = {
  chapterId: number;
  debug: any;
  reason: ContentAuthorizationDenyReason;
  status: ContentAuthorizationStatus.denied;
  type: typeof CONTENT_AUTHORIZATION_STATUS_UPDATED;
};

type ContentAuthorizationStatusLoadingArgs = Omit<
  ContentAuthorizationStatusLoadingAction,
  'type'
>;
const contentAuthorizationStatusLoading = ({
  chapterId,
  status,
}: ContentAuthorizationStatusLoadingArgs): ContentAuthorizationStatusLoadingAction => ({
  type: CONTENT_AUTHORIZATION_STATUS_UPDATED,
  chapterId,
  status,
});

type ContentAuthorizationStatusGrantedArgs = Omit<
  ContentAuthorizationStatusGrantedAction,
  'type'
>;
const contentAuthorizationStatusGranted = ({
  chapterId,
  debug,
  status,
}: ContentAuthorizationStatusGrantedArgs): ContentAuthorizationStatusGrantedAction => ({
  type: CONTENT_AUTHORIZATION_STATUS_UPDATED,
  chapterId,
  status,
  debug,
});

type ContentAuthorizationStatusDeniedArgs = Omit<
  ContentAuthorizationStatusDeniedAction,
  'type'
>;
const contentAuthorizationStatusDenied = ({
  chapterId,
  debug,
  reason,
  status,
}: ContentAuthorizationStatusDeniedArgs): ContentAuthorizationStatusDeniedAction => ({
  type: CONTENT_AUTHORIZATION_STATUS_UPDATED,
  chapterId,
  reason,
  status,
  debug,
});

const reportSentryError = (
  errorResult: ContentAuthorizationErrorResult,
): void => {
  switch (errorResult.type) {
    case 'invalid-access-value':
      raven.captureMessage('CAS: invalid access value', {
        level: 'warning',
        extra: { access: errorResult.access },
      });
      break;
    case 'unexpected-status':
      raven.captureMessage('CAS: unexpected status', {
        level: 'warning',
        extra: { status: errorResult.status },
      });
      break;
    case 'unhandled-error':
      raven.captureMessage('CAS: unhandled error', {
        level: 'warning',
        extra: { error: errorResult.error },
      });
      break;
    default:
      unreachable(errorResult);
  }
};

export const fetchContentAuthorization =
  (chapterId: number, casClient: ContentAuthorizationClient) =>
  async (dispatch: Dispatch) => {
    dispatch(
      contentAuthorizationStatusLoading({
        chapterId,
        status: ContentAuthorizationStatus.loading,
      }),
    );

    const authResponse = await casClient.authorize(`chapter:${chapterId}`);

    switch (authResponse.access) {
      case ContentAuthorizationAccess.GRANTED:
      case ContentAuthorizationAccess.INDETERMINATE:
        switch (authResponse.decisionSource) {
          case 'grant-with-error':
            reportSentryError(authResponse.errorResult);
            break;
          case 'circuit-breaker':
          case 'policy':
          case 'grant-fallback':
            break;
          default:
            unreachable(authResponse);
        }
        return dispatch(
          contentAuthorizationStatusGranted({
            chapterId,
            status: ContentAuthorizationStatus.granted,
            debug: authResponse,
          }),
        );
      case ContentAuthorizationAccess.DENIED:
        return dispatch(
          contentAuthorizationStatusDenied({
            chapterId,
            status: ContentAuthorizationStatus.denied,
            reason: authResponse.denyReason,
            debug: authResponse,
          }),
        );
      default:
        return unreachable(authResponse);
    }
  };

export type ContentAuthorizationStatusActions =
  | ContentAuthorizationStatusDeniedAction
  | ContentAuthorizationStatusGrantedAction
  | ContentAuthorizationStatusLoadingAction;
