import trackProgressGtm from '../../helpers/trackProgressGtm';

import { emptyAction } from './index';
import type { ResizeEditorsAction } from './settings';

export const UPDATE_EXERCISES_PROGRESS = 'exercises/UPDATE_EXERCISES_PROGRESS';
export type UpdateExercisesProgressAction = {
  data: any;
  type: typeof UPDATE_EXERCISES_PROGRESS;
};

export const STOP_BACKEND_SESSION = 'exercises/STOP_BACKEND_SESSION';
type StopBackendSession = {
  type: typeof STOP_BACKEND_SESSION;
};
export const stopBackendSession = () => ({ type: STOP_BACKEND_SESSION });

export const SAVE_CODE = 'exercises/SAVE_CODE';
export type SaveCodeArgs = {
  category: string;
  code: string;
  extra?: Record<string, unknown>;
  id: number;
  tabKey: string;
};
export type SaveCodeAction = SaveCodeArgs & { type: typeof SAVE_CODE };
export const saveCode = ({
  category,
  code,
  extra = {},
  id,
  tabKey,
}: SaveCodeArgs): SaveCodeAction => ({
  type: SAVE_CODE,
  id,
  code,
  tabKey,
  category,
  extra,
});

export const UPDATE_CURRENT_EXERCISE = 'exercises/UPDATE_CURRENT_EXERCISE';
export type UpdateCurrentExerciseAction = {
  index: any;
  type: typeof UPDATE_CURRENT_EXERCISE;
};
export const updateCurrentExercise =
  ({ index }: any) =>
  (dispatch: any) =>
    dispatch({
      type: UPDATE_CURRENT_EXERCISE,
      index,
    });

export const EPIC_UPDATE_EXERCISES = 'exercises/EPIC_UPDATE_EXERCISES';
export const epicUpdateExercises = ({
  chapterRef,
  chapters,
  courseRef,
  exIndex,
  injection,
}: any) => ({
  type: EPIC_UPDATE_EXERCISES,
  chapterRef,
  courseRef,
  exIndex,
  chapters,
  injection,
});

export const FIGURE_EXPANDED = 'exercises/FIGURE_EXPANDED';
export type FigureExpandedAction = {
  category: unknown;
  index: number;
  src: unknown;
  tabKey: unknown;
  type: typeof FIGURE_EXPANDED;
};
export const figureExpanded = ({
  category,
  index,
  src,
  tabKey,
}: any): FigureExpandedAction => ({
  type: FIGURE_EXPANDED,
  category,
  tabKey,
  index,
  src,
});

export const SHOW_BOKEH = 'exercises/SHOW_BOKEH';
export type ShowBokehAction = {
  html: any;
  src: any;
  type: typeof SHOW_BOKEH;
};
export const showBokeh = ({ html, src }: any): ShowBokehAction => ({
  type: SHOW_BOKEH,
  src,
  html,
});

export const SHOW_R_DOC = 'exercises/SHOW_R_DOC';
export type ShowRDocAction = {
  payload: any;
  type: typeof SHOW_R_DOC;
};
export const showRDoc = ({ payload }: any): ShowRDocAction => ({
  type: SHOW_R_DOC,
  payload,
});

export const NEW_HTML = 'exercises/NEW_HTML';
export type NewHtmlAction = {
  html: unknown;
  isRelativeToMultiplexer: boolean;
  src: unknown;
  type: typeof NEW_HTML;
};
export const newHtml = ({
  html,
  isRelativeToMultiplexer,
  src,
}: any): NewHtmlAction => ({
  type: NEW_HTML,
  src,
  html,
  isRelativeToMultiplexer,
});

export const ADD_PROXY = 'exercises/ADD_PROXY';
export type AddProxyAction = {
  proxyId: number;
  type: typeof ADD_PROXY;
};
export const addProxy = ({ proxyId }: any) => ({ type: ADD_PROXY, proxyId });

export const NEW_PROXY = 'exercises/NEW_PROXY';
export type NewProxyAction = {
  payload: unknown;
  type: typeof NEW_PROXY;
};
export const newProxy = ({ payload }: any): NewProxyAction => ({
  type: NEW_PROXY,
  payload,
});

export const NEW_SHELL_PROXY = 'exercises/NEW_SHELL_PROXY';
export type NewShellProxyAction = {
  payload: unknown;
  type: typeof NEW_SHELL_PROXY;
};
export const newShellProxy = ({ payload }: any): NewShellProxyAction => ({
  type: NEW_SHELL_PROXY,
  payload,
});

export const NEW_PDF_DEPRECATED = 'exercises/NEW_PDF_DEPRECATED';
export type NewPdfDeprecatedAction = {
  src: unknown;
  type: typeof NEW_PDF_DEPRECATED;
};
export const newPdfDeprecated = ({ src }: any): NewPdfDeprecatedAction => ({
  type: NEW_PDF_DEPRECATED,
  src,
});

export const NEW_PDF = 'exercises/NEW_PDF';
export type NewPdfAction = {
  isRelativeToMultiplexer: boolean;
  src: unknown;
  type: typeof NEW_PDF;
};
export const newPdf = ({
  isRelativeToMultiplexer,
  src,
}: any): NewPdfAction => ({
  type: NEW_PDF,
  src,
  isRelativeToMultiplexer,
});

export const NEW_PLOT = 'exercises/NEW_PLOT';
export type NewPlotAction = {
  type: typeof NEW_PLOT;
  url: string;
};
export const newPlot = ({ url }: any) => ({ type: NEW_PLOT, url });

export const RERENDER_PLOT = 'exercises/RERENDER_PLOT';
export type RerenderPlotAction = {
  index: number;
  type: typeof RERENDER_PLOT;
  url: string;
};
export const rerenderPlot = ({ index, url }: any): RerenderPlotAction => ({
  type: RERENDER_PLOT,
  index,
  url,
});

export const RESULT_EXERCISE = 'exercises/RESULT_EXERCISE';

export type ResultExerciseAction = {
  results: any;
  timestamp: number;
  type: typeof RESULT_EXERCISE;
};
export const resultExercise = ({
  results,
  timestamp = Date.now(),
}: any): ResultExerciseAction => ({
  type: RESULT_EXERCISE,
  results,
  timestamp,
});

export const COMPLETE_SUB_EXERCISE = 'exercises/COMPLETE_SUB_EXERCISE';
export type CompleteSubExerciseAction = {
  completed: boolean;
  isLastExercise: boolean;
  message: string;
  show: boolean;
  submitSettings: unknown;
  type: typeof COMPLETE_SUB_EXERCISE;
};
export const completeSubExercise = ({
  completed,
  isLastExercise = false,
  message,
  show,
  submitSettings = {},
}: any): CompleteSubExerciseAction => ({
  type: COMPLETE_SUB_EXERCISE,
  message,
  show,
  completed,
  submitSettings,
  isLastExercise,
});

export const EPIC_COMPLETING_SUB_EXERCISE =
  'exercises/EPIC_COMPLETING_SUB_EXERCISE';
export type EpicCompletingSubExerciseAction = {
  completed: boolean;
  message: string;
  show: boolean;
  submitSettings: unknown;
  type: typeof EPIC_COMPLETING_SUB_EXERCISE;
};
export const epicCompletingSubExercise = ({
  completed,
  message,
  show,
  submitSettings = {},
}: any): EpicCompletingSubExerciseAction => ({
  type: EPIC_COMPLETING_SUB_EXERCISE,
  message,
  show,
  completed,
  submitSettings,
});

export const COMPLETE_EXERCISE = 'exercises/COMPLETE_EXERCISE';

export const completeExercise =
  ({ completed, message, show, submitSettings = {} }: any) =>
  (dispatch: any, getState: any) => {
    const prevState = getState().toJS();
    dispatch({
      type: COMPLETE_EXERCISE,
      message,
      show,
      completed,
      submitSettings,
    });
    const newState = getState().toJS();
    trackProgressGtm(prevState, newState);
    return emptyAction;
  };
export type CompleteExerciseAction = {
  completed: boolean;
  message: string;
  show: boolean;
  submitSettings: {
    code: string;
    correct: boolean;
    message: string;
    xp: number;
  };
  type: typeof COMPLETE_EXERCISE;
};

export const EXERCISE_COMPLETED = 'exercises/EXERCISE_COMPLETED';
export type ExerciseCompletedAction = {
  currentTime: number;
  type: typeof EXERCISE_COMPLETED;
  xpGained: number;
};
export const exerciseCompleted = ({
  currentTime,
  xpGained,
}: Omit<ExerciseCompletedAction, 'type'>): ExerciseCompletedAction => ({
  type: EXERCISE_COMPLETED,
  xpGained,
  currentTime,
});

export const HIDE_COMPLETED_EXERCISE = 'exercises/HIDE_COMPLETED_EXERCISE';
export type HideCompletedExerciseAction = {
  id: number;
  type: typeof HIDE_COMPLETED_EXERCISE;
};
export const hideCompletedExercise = ({
  id,
}: any): HideCompletedExerciseAction => ({
  type: HIDE_COMPLETED_EXERCISE,
  id,
});

export const UPDATE_HIGHLIGHT = 'exercises/UPDATE_HIGHLIGHT';
export type UpdateHighlightAction = {
  id: number;
  output: unknown;
  type: typeof UPDATE_HIGHLIGHT;
};
export const updateHighlight = ({
  id,
  output,
}: any): UpdateHighlightAction => ({
  type: UPDATE_HIGHLIGHT,
  output,
  id,
});

export const UPDATE_FEEDBACK_MESSAGE = 'exercises/UPDATE_FEEDBACK_MESSAGE';
export type UpdateFeedbackMessageAction = {
  output: unknown;
  type: typeof UPDATE_FEEDBACK_MESSAGE;
};
export const updateFeedbackMessage = ({
  output,
}: any): UpdateFeedbackMessageAction => ({
  type: UPDATE_FEEDBACK_MESSAGE,
  output,
});

export const UPDATE_FEEDBACK_MESSAGE_WITH_ASSISTANT =
  'exercises/UPDATE_FEEDBACK_MESSAGE_WITH_ASSISTANT';
export type UpdateFeedbackMessageWithAssistantAction = {
  error: string;
  type: typeof UPDATE_FEEDBACK_MESSAGE_WITH_ASSISTANT;
};
export const updateFeedbackMessageWithAssistant = ({
  error,
}: any): UpdateFeedbackMessageWithAssistantAction => ({
  type: UPDATE_FEEDBACK_MESSAGE_WITH_ASSISTANT,
  error,
});

export const UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX =
  'exercises/UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX';
export type UpdateFeedbackMessageOfSubExAction = {
  output: unknown;
  type: typeof UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX;
};
export const updateFeedbackMessageOfSubEx = ({
  output,
}: any): UpdateFeedbackMessageOfSubExAction => ({
  type: UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX,
  output,
});

export const UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX_WITH_ASSISTANT =
  'exercises/UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX_WITH_ASSISTANT';
export type UpdateFeedbackMessageOfSubExWithAssistantAction = {
  error: string;
  type: typeof UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX_WITH_ASSISTANT;
};
export const updateFeedbackMessageOfSubExWithAssistant = ({
  error,
}: any): UpdateFeedbackMessageOfSubExWithAssistantAction => ({
  type: UPDATE_FEEDBACK_MESSAGE_OF_SUB_EX_WITH_ASSISTANT,
  error,
});

export const UPDATE_FEEDBACK_TAB = 'exercises/UPDATE_FEEDBACK_TAB';
type UpdateFeedbackTabAction = {
  tabIndex: number;
  type: typeof UPDATE_FEEDBACK_TAB;
};
export const updateFeedbackTab = ({
  tabIndex,
}: {
  tabIndex: number;
}): UpdateFeedbackTabAction => ({
  type: UPDATE_FEEDBACK_TAB,
  tabIndex,
});

type ShowHintAction = {
  exerciseId: number;
  type: typeof SHOW_HINT;
  xp: number;
};

export const SHOW_HINT = 'exercises/SHOW_HINT';
export const showHint = (exerciseId: number, xp: number): ShowHintAction => ({
  type: SHOW_HINT,
  exerciseId,
  xp,
});

type SetHintShownAction = {
  isHintShown: boolean;
  type: typeof SET_HINT_SHOWN;
};

export const SET_HINT_SHOWN = 'exercises/SET_HINT_SHOWN';
export const setHintShown = (isHintShown: boolean): SetHintShownAction => ({
  type: SET_HINT_SHOWN,
  isHintShown,
});

export const SHOW_SOLUTION = 'exercises/SHOW_SOLUTION';

type ShowSolutionAction = {
  exerciseId: number;
  language: string;
  type: typeof SHOW_SOLUTION;
  xp: number;
};

export const showSolution = (
  language: string,
  exerciseId: number,
  xp: number,
): ShowSolutionAction => ({
  type: SHOW_SOLUTION,
  exerciseId,
  xp,
  language,
});

export const UPDATE_CONSOLE_CODE = 'exercises/UPDATE_CONSOLE_CODE';
export type UpdateConsoleCodeAction = {
  output: unknown;
  type: typeof UPDATE_CONSOLE_CODE;
};
export const updateConsoleCode = ({
  output,
}: any): UpdateConsoleCodeAction => ({
  type: UPDATE_CONSOLE_CODE,
  output,
});

export const CHOOSE_ANSWER = 'exercises/CHOOSE_ANSWER';
export type ChooseAnswerAction = {
  choice: unknown;
  index: number;
  type: typeof CHOOSE_ANSWER;
};
export const chooseAnswer = ({ choice, index }: any): ChooseAnswerAction => ({
  type: CHOOSE_ANSWER,
  choice,
  index,
});

export const SET_MCE_CHOICES_FOCUS = 'exercises/SET_MCE_CHOICES_FOCUS';
export type SetMceChoicesFocusAction = {
  focused: boolean;
  type: typeof SET_MCE_CHOICES_FOCUS;
};
export const setMceChoicesFocus = ({
  focused,
}: any): SetMceChoicesFocusAction => ({
  type: SET_MCE_CHOICES_FOCUS,
  focused,
});

export const UPDATE_CURRENT_GRAPHICAL_OUTPUT =
  'exercises/UPDATE_CURRENT_GRAPHICAL_OUTPUT';
export type UpdateCurrentGraphicalOutputAction = {
  category: unknown;
  index: number;
  key: unknown;
  type: typeof UPDATE_CURRENT_GRAPHICAL_OUTPUT;
};
export const updateCurrentGraphicalOutput = ({
  category,
  index,
  key,
}: any): UpdateCurrentGraphicalOutputAction => ({
  type: UPDATE_CURRENT_GRAPHICAL_OUTPUT,
  key,
  index,
  category,
});

export const EXPAND_GRAPHICAL_OUTPUT = 'exercises/EXPAND_GRAPHICAL_OUTPUT';
export type ExpandGraphicalOutputAction = {
  category: unknown;
  id: unknown;
  key: unknown;
  type: typeof EXPAND_GRAPHICAL_OUTPUT;
};
export const expandGraphicalOutput = ({
  category,
  id,
  key,
}: any): ExpandGraphicalOutputAction => ({
  type: EXPAND_GRAPHICAL_OUTPUT,
  key,
  category,
  id,
});
export const FOCUS_GRAPHICAL_OUTPUT = 'exercises/FOCUS_GRAPHICAL_OUTPUT';
export type FocusGraphicalOutputAction = {
  category: unknown;
  id: unknown;
  key: unknown;
  type: typeof FOCUS_GRAPHICAL_OUTPUT;
};
export const focusGraphicalOutput = ({ category, id, key }: any) => ({
  type: FOCUS_GRAPHICAL_OUTPUT,
  key,
  category,
  id,
});
export const BLUR_GRAPHICAL_OUTPUT = 'exercises/BLUR_GRAPHICAL_OUTPUT';
export type BlurGraphicalOutputAction = {
  category: unknown;
  id: unknown;
  key: unknown;
  type: typeof BLUR_GRAPHICAL_OUTPUT;
};
export const blurGraphicalOutput = ({
  category,
  id,
  key,
}: any): BlurGraphicalOutputAction => ({
  type: BLUR_GRAPHICAL_OUTPUT,
  key,
  category,
  id,
});
export const CLOSE_GRAPHICAL_OUTPUT = 'exercises/CLOSE_GRAPHICAL_OUTPUT';
export type CloseGraphicalOutputAction = {
  category: unknown;
  id: unknown;
  key: unknown;
  type: typeof CLOSE_GRAPHICAL_OUTPUT;
};
export const closeGraphicalOutput = ({
  category,
  id,
  key,
}: any): CloseGraphicalOutputAction => ({
  type: CLOSE_GRAPHICAL_OUTPUT,
  key,
  category,
  id,
});

export const UPDATE_ACTIVE_TAB = 'exercises/UPDATE_ACTIVE_TAB';
export type UpdateActiveTabAction = {
  activeKey: string;
  category: string;
  type: typeof UPDATE_ACTIVE_TAB;
};
export const updateActiveTab = ({
  activeKey,
  category,
}: Omit<UpdateActiveTabAction, 'type'>): UpdateActiveTabAction => ({
  type: UPDATE_ACTIVE_TAB,
  category,
  activeKey,
});

export const ADD_TAB = 'exercises/ADD_TAB';
export type AddTabAction = {
  category: string;
  key: string;
  title: string;
  type: typeof ADD_TAB;
};
export const addTab = ({
  category,
  key,
  title,
}: Omit<AddTabAction, 'type'>): AddTabAction => ({
  type: ADD_TAB,
  category,
  key,
  title,
});

export const UPDATE_ACTIVE_INSTRUCTION_TAB =
  'exercises/UPDATE_ACTIVE_INSTRUCTION_TAB';
export type UpdateActiveInstructionTabAction = {
  activeKey: string;
  type: typeof UPDATE_ACTIVE_INSTRUCTION_TAB;
};
export const updateActiveInstructionTab = ({
  activeKey,
}: any): UpdateActiveInstructionTabAction => ({
  type: UPDATE_ACTIVE_INSTRUCTION_TAB,
  activeKey,
});

export const UPDATE_MARKDOWN_ACTIVE_TITLE =
  'exercises/UPDATE_MARKDOWN_ACTIVE_TITLE';
export type UpdateMarkdownActiveTitleAction = {
  activeTitle: string;
  type: typeof UPDATE_MARKDOWN_ACTIVE_TITLE;
};
export const updateMarkdownActiveTitle = ({
  activeTitle,
}: any): UpdateMarkdownActiveTitleAction => ({
  type: UPDATE_MARKDOWN_ACTIVE_TITLE,
  activeTitle,
});

export const SET_LTI_STATUS = 'exercises/SET_LTI_STATUS';
export type SetLtiStatusAction = {
  message: string;
  status: unknown;
  type: typeof SET_LTI_STATUS;
};
export const setLtiStatus = ({ message, status }: any): SetLtiStatusAction => ({
  type: SET_LTI_STATUS,
  status,
  message,
});

export const RETRY_REQUEST_TO_LTI = 'exercises/RETRY_REQUEST_TO_LTI';
export type RetryRequestToLtiAction = {
  type: typeof RETRY_REQUEST_TO_LTI;
};
export const retryRequestToLti = (): RetryRequestToLtiAction => ({
  type: RETRY_REQUEST_TO_LTI,
});

export const EPIC_SUBMIT_EXERCISE_RATING =
  'exercises/EPIC_SUBMIT_EXERCISE_RATING';
export const epicSubmitExerciseRating = ({
  extraInfo,
  nextPath,
  rating,
}: any): SubmitExerciseRatingAction => ({
  type: EPIC_SUBMIT_EXERCISE_RATING,
  rating,
  extraInfo,
  nextPath,
});
export type SubmitExerciseRatingAction = {
  extraInfo?: any;
  nextPath?: string | null | undefined;
  rating: any;
  type: typeof EPIC_SUBMIT_EXERCISE_RATING;
};

export const EPIC_SUBMIT_CHAPTER_RATING =
  'exercises/EPIC_SUBMIT_CHAPTER_RATING';
export const epicSubmitChapterRating = ({
  extraInfo,
  onFinish,
  rating,
}: any) => ({
  type: EPIC_SUBMIT_CHAPTER_RATING,
  rating,
  onFinish,
  extraInfo,
});

export const EPIC_RESET_CODE = 'exercises/EPIC_RESET_CODE';
export const epicResetCode = () => ({ type: EPIC_RESET_CODE });

export const SET_SIDE_PANEL_CLOSED_STATE =
  'exercises/SET_SIDE_PANEL_CLOSED_STATE';
export type SetSidePanelClosedStateAction = {
  closed: boolean;
  type: typeof SET_SIDE_PANEL_CLOSED_STATE;
};
export const setSidePanelClosedState = (
  closed: any,
): SetSidePanelClosedStateAction => ({
  type: SET_SIDE_PANEL_CLOSED_STATE,
  closed,
});

export const SET_BOTTOM_PANEL_CLOSED_STATE =
  'exercises/SET_BOTTOM_PANEL_CLOSED_STATE';
export type SetBottomPanelClosedStateAction = {
  closed: boolean;
  type: typeof SET_BOTTOM_PANEL_CLOSED_STATE;
};
export const setBottomPanelClosedState = (
  closed: any,
): SetBottomPanelClosedStateAction => ({
  type: SET_BOTTOM_PANEL_CLOSED_STATE,
  closed,
});

export const UPDATE_SQL_QUERY_RESULT = 'exercises/UPDATE_SQL_QUERY_RESULT';
export type UpdateSqlQueryResultAction = {
  settings: any;
  type: typeof UPDATE_SQL_QUERY_RESULT;
};
export const updateSqlQueryResult = (
  settings: any,
): UpdateSqlQueryResultAction => ({
  type: UPDATE_SQL_QUERY_RESULT,
  settings,
});

export const UPDATE_SQL_QUERY_MESSAGE = 'exercises/UPDATE_SQL_QUERY_MESSAGE';
export type UpdateSqlQueryMessageAction = {
  settings: any;
  type: typeof UPDATE_SQL_QUERY_MESSAGE;
};
export const updateSqlQueryMessage = (
  settings: any,
): UpdateSqlQueryMessageAction => ({
  type: UPDATE_SQL_QUERY_MESSAGE,
  settings,
});

export const UPDATE_SQL_TABLE = 'exercises/UPDATE_SQL_TABLE';
export type UpdateSqlTableAction = {
  settings: any;
  type: typeof UPDATE_SQL_TABLE;
};
export const updateSqlTable = (settings: any): UpdateSqlTableAction => ({
  type: UPDATE_SQL_TABLE,
  settings,
});

export const UPDATE_OBJECT_VIEW = 'exercises/UPDATE_OBJECT_VIEW';
export type UpdateObjectViewAction = {
  settings: any;
  type: typeof UPDATE_OBJECT_VIEW;
};
export const updateObjectView = (settings: any): UpdateObjectViewAction => ({
  type: UPDATE_OBJECT_VIEW,
  settings,
});

export const UPDATE_SQL_OPTIONS = 'exercises/UPDATE_SQL_OPTIONS';
export type UpdateSqlAction = {
  settings: any;
  type: typeof UPDATE_SQL_OPTIONS;
};
export const updateSqlOptions = (settings: any): UpdateSqlAction => ({
  type: UPDATE_SQL_OPTIONS,
  settings,
});

export const UPDATE_SQL_ERROR = 'exercises/UPDATE_SQL_ERROR';
export type UpdateSqlErrorAction = {
  settings: unknown;
  type: typeof UPDATE_SQL_ERROR;
};
export const updateSqlError = (settings: any): UpdateSqlErrorAction => ({
  type: UPDATE_SQL_ERROR,
  settings,
});

export const TOGGLE_SQL_ERROR = 'exercises/TOGGLE_SQL_ERROR';
export type ToggleSqlErrorAction = {
  show: boolean;
  type: typeof TOGGLE_SQL_ERROR;
};
export const toggleSqlError = (show: any): ToggleSqlErrorAction => ({
  type: TOGGLE_SQL_ERROR,
  show,
});

export const UPDATE_SQL_TABLE_NAMES = 'exercises/UPDATE_SQL_TABLE_NAMES';
export type UpdateSqlTableNamesAction = {
  tableNames: any;
  type: typeof UPDATE_SQL_TABLE_NAMES;
};
export const updateSqlTableNames = (tableNames: any) => ({
  type: UPDATE_SQL_TABLE_NAMES,
  tableNames,
});

export const COPY_SOLUTION = 'exercises/COPY_SOLUTION';
export type CopySolutionAction = {
  code: string;
  type: typeof COPY_SOLUTION;
};
export const copySolution = ({ code }: any): CopySolutionAction => ({
  type: COPY_SOLUTION,
  code,
});

export const UPDATE_ATTEMPTS = 'exercises/UPDATE_ATTEMPTS';
export type UpdateAttemptsAction = {
  nbAttempts: number;
  type: typeof UPDATE_ATTEMPTS;
};
export const updateAttempts = ({ nbAttempts }: any) => ({
  type: UPDATE_ATTEMPTS,
  nbAttempts,
});

export const CLOSE_EDITOR_TAB = 'exercises/CLOSE_EDITOR_TAB';
export type CloseEditorTabAction = {
  tabKey: string;
  type: typeof CLOSE_EDITOR_TAB;
};
export const closeEditorTab = ({ tabKey }: any): CloseEditorTabAction => ({
  type: CLOSE_EDITOR_TAB,
  tabKey,
});

export const NEXT_INTERNAL_EXERCISE = 'exercises/NEXT_INTERNAL_EXERCISE';
export const nextInternalExercise = (): NextInternalExerciseAction => ({
  type: NEXT_INTERNAL_EXERCISE,
});
export type NextInternalExerciseAction = {
  type: typeof NEXT_INTERNAL_EXERCISE;
};

export const SET_NEXT_PATH = 'exercises/SET_NEXT_PATH';
export type SetNextPathAction = {
  nextPath: any;
  type: typeof SET_NEXT_PATH;
};
export const setNextPath = (nextPath: any): SetNextPathAction => ({
  type: SET_NEXT_PATH,
  nextPath,
});
export const SET_USER_USED_AI_INCORRECT_SUBMISSIONS =
  'exercises/SET_USER_USED_AI_INCORRECT_SUBMISSIONS';
export type SetUserUsedAiIncorrectSubmissionsAction = {
  type: typeof SET_USER_USED_AI_INCORRECT_SUBMISSIONS;
  value: boolean;
};
export const setUserUsedAiIncorrectSubmissions = (
  value: boolean,
): SetUserUsedAiIncorrectSubmissionsAction => ({
  type: SET_USER_USED_AI_INCORRECT_SUBMISSIONS,
  value,
});
export const SET_USER_USED_AI_ERROR_EXPLANATION =
  'exercises/SET_USER_USED_AI_ERROR_EXPLANATION';
export type SetUserUsedAiErrorExplanationAction = {
  type: typeof SET_USER_USED_AI_ERROR_EXPLANATION;
  value: boolean;
};
export const setUserUsedAiErrorExplanation = (
  value: boolean,
): SetUserUsedAiErrorExplanationAction => ({
  type: SET_USER_USED_AI_ERROR_EXPLANATION,
  value,
});

export type ExerciseActions =
  | AddProxyAction
  | AddTabAction
  | BlurGraphicalOutputAction
  | ChooseAnswerAction
  | CloseEditorTabAction
  | CloseGraphicalOutputAction
  | CompleteExerciseAction
  | CompleteSubExerciseAction
  | CopySolutionAction
  | EpicCompletingSubExerciseAction
  | ExerciseCompletedAction
  | ExpandGraphicalOutputAction
  | FigureExpandedAction
  | FocusGraphicalOutputAction
  | HideCompletedExerciseAction
  | NewHtmlAction
  | NewPdfAction
  | NewPdfDeprecatedAction
  | NewPlotAction
  | NewProxyAction
  | NewShellProxyAction
  | NextInternalExerciseAction
  | RerenderPlotAction
  | ResizeEditorsAction
  | ResultExerciseAction
  | RetryRequestToLtiAction
  | SaveCodeAction
  | SetBottomPanelClosedStateAction
  | SetHintShownAction
  | SetLtiStatusAction
  | SetMceChoicesFocusAction
  | SetNextPathAction
  | SetSidePanelClosedStateAction
  | SetUserUsedAiErrorExplanationAction
  | SetUserUsedAiIncorrectSubmissionsAction
  | ShowBokehAction
  | ShowHintAction
  | ShowRDocAction
  | ShowSolutionAction
  | StopBackendSession
  | SubmitExerciseRatingAction
  | ToggleSqlErrorAction
  | UpdateActiveInstructionTabAction
  | UpdateActiveTabAction
  | UpdateAttemptsAction
  | UpdateConsoleCodeAction
  | UpdateCurrentExerciseAction
  | UpdateCurrentGraphicalOutputAction
  | UpdateExercisesProgressAction
  | UpdateFeedbackMessageAction
  | UpdateFeedbackMessageOfSubExAction
  | UpdateFeedbackMessageOfSubExWithAssistantAction
  | UpdateFeedbackMessageWithAssistantAction
  | UpdateFeedbackTabAction
  | UpdateHighlightAction
  | UpdateMarkdownActiveTitleAction
  | UpdateObjectViewAction
  | UpdateSqlAction
  | UpdateSqlErrorAction
  | UpdateSqlQueryMessageAction
  | UpdateSqlQueryResultAction
  | UpdateSqlTableAction
  | UpdateSqlTableNamesAction;
