import type { State } from '../../interfaces/State';

export const REQUEST_STATUS = {
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  NOT_FETCHED: 'NOT_FETCHED',
} as const;

export type RequestStatus =
  (typeof REQUEST_STATUS)[keyof typeof REQUEST_STATUS];

export const selectPreFetchedData = (state: State) =>
  state.get('preFetchedData');
