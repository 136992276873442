export const middlewareReduxArray =
  (store: any) => (next: any) => (action: any) => {
    if (Array.isArray(action)) {
      const results = action.map(store.dispatch);
      if (results.find((obj) => obj instanceof Promise) != null) {
        return Promise.all(results);
      }
      return results;
    }
    return next(action);
  };
