export const ADVANCE_ONBOARDING_MILESTONES =
  'onboardingMilestones/ADVANCE_ONBOARDING_MILESTONES';

export const advanceOnboardingMilestones =
  (): AdvanceOnboardingMilestonesAction => ({
    type: ADVANCE_ONBOARDING_MILESTONES,
  });

export type AdvanceOnboardingMilestonesAction = {
  type: typeof ADVANCE_ONBOARDING_MILESTONES;
};

export const SKIP_ONBOARDING_MILESTONES =
  'onboardingMilestones/SKIP_ONBOARDING_MILESTONES';

export const skipOnboardingMilestones = (): SkipOnboardingMilestonesAction => ({
  type: SKIP_ONBOARDING_MILESTONES,
});

export type SkipOnboardingMilestonesAction = {
  type: typeof SKIP_ONBOARDING_MILESTONES;
};

export const ONBOARDING_MILESTONES_STARTED =
  'onboardingMilestones/ONBOARDING_MILESTONES_STARTED';

export const onboardingMilestonesStarted =
  (): OnboardingMilestonesStartedAction => ({
    type: ONBOARDING_MILESTONES_STARTED,
  });

export type OnboardingMilestonesStartedAction = {
  type: typeof ONBOARDING_MILESTONES_STARTED;
};

export type OnboardingMilestonesActions =
  | AdvanceOnboardingMilestonesAction
  | OnboardingMilestonesStartedAction
  | SkipOnboardingMilestonesAction;
