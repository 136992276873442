import attempt from 'lodash/attempt';
import isEqual from 'lodash/isEqual';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import { getCodeExecutionBackend } from '../../helpers/codeExecutionBackend';
import { isExercisePage } from '../../helpers/navigation';
import * as actions from '../actions';
import * as selectors from '../selectors';

export const epicUpdateLocation = (action$: any, store: any) =>
  action$
    .ofType(actions.UPDATE_LOCATION)
    .distinctUntilChanged(isEqual)
    // Side-effect to stop polling the mux when moving from an exercise to another one
    .do(() => {
      const previousExercise = selectors
        .selectPreviousQuery(store.getState())
        .get('ex');
      const nextExercise = selectors.selectQuery(store.getState()).get('ex');
      if (!isNil(previousExercise) && nextExercise !== previousExercise) {
        attempt(() => getCodeExecutionBackend()?.stopPolling());
      }
    })
    .switchMap((action: any) => {
      if (
        isExercisePage(
          selectors.selectLocation(store.getState()).get('pathname'),
        )
      ) {
        return Rx.Observable.of(
          actions.updateMidLocation({ ...action }),
          actions.removeModal({ force: true }),
          actions.removeWindow(),
          actions.epicUpdateMetaTags(),
        );
      }
      return Rx.Observable.of(actions.epicUpdateMetaTags());
    });
