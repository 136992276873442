/* eslint-disable no-param-reassign */
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/redux-raven-middleware` if... Remove this comment to see the full error message
import RavenMiddleware from 'redux-raven-middleware';

import config from '../../config';
import { RAVEN_CONFIG, SENTRY_DSN } from '../../helpers/raven';
import * as selectors from '../selectors';

let globalStore: any = null;
export const middlewareRecordStore =
  (store: any) => (next: any) => (action: any) => {
    globalStore = store;
    next(action);
  };

const stateTransformer = (state: any) => {
  const currentExercise = selectors.selectExercise(state);
  return state
    .delete('exercises')
    .set('currentExercise', currentExercise)
    .delete('preFetchedData');
};

let sentryMidd = () => (next: any) => (action: any) => next(action);
if (config.sentryEnabled) {
  let nbOfErrors = 0;
  sentryMidd = RavenMiddleware(
    SENTRY_DSN,
    {
      ...RAVEN_CONFIG,
      dataCallback: (data: any) => {
        if (!data.extra) {
          data.extra = {};
        }
        if (!data.extra.state) {
          data.extra.state =
            globalStore && stateTransformer(globalStore.getState()).toJS();
        }
        return data;
      },
      shouldSendCallback: () => {
        nbOfErrors += 1;
        return nbOfErrors <= 2;
      },
    },
    {
      stateTransformer,
    },
  );
}

export const middlewareSentry = sentryMidd;
