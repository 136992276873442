import { Map, Record } from 'immutable';

import type { Reducer } from '../../interfaces/State';
import * as actions from '../actions';
import type { RequestStatus } from '../selectors';
import { REQUEST_STATUS } from '../selectors';

export type IPreFetchedRequest = {
  data: unknown | null;
  status: RequestStatus;
};

export const PreFetchedRequestRecord = Record<IPreFetchedRequest>(
  {
    status: REQUEST_STATUS.NOT_FETCHED,
    data: null,
  },
  'PreFetchedRequestRecord',
);

type PreFetchedDataStateShape = {
  categoryPages: Record.Instance<IPreFetchedRequest>;
  chapter: Record.Instance<IPreFetchedRequest>;
  course: Record.Instance<IPreFetchedRequest>;
  courseImages: Record.Instance<IPreFetchedRequest>;
  exercises: Record.Instance<IPreFetchedRequest>;
  learningRecap: Record.Instance<IPreFetchedRequest>;
  sharedImage: Record.Instance<IPreFetchedRequest>;
  translatedCourses: Record.Instance<IPreFetchedRequest>;
  workspaceTemplate: Record.Instance<IPreFetchedRequest>;
};

export type PrefetchedData = Partial<{
  [key in keyof PreFetchedDataStateShape]: IPreFetchedRequest;
}>;

export const PreFetchedDataStateRecord = Record<PreFetchedDataStateShape>(
  {
    chapter: new PreFetchedRequestRecord({}),
    course: new PreFetchedRequestRecord({}),
    exercises: new PreFetchedRequestRecord({}),
    learningRecap: new PreFetchedRequestRecord({}),
    sharedImage: new PreFetchedRequestRecord({}),
    workspaceTemplate: new PreFetchedRequestRecord({}),
    courseImages: new PreFetchedRequestRecord({}),
    categoryPages: new PreFetchedRequestRecord({}),
    translatedCourses: new PreFetchedRequestRecord({}),
  },
  'PreFetchedDataStateRecord',
);
export type PreFetchedDataState = Record.Instance<PreFetchedDataStateShape>;

const initialState = new PreFetchedDataStateRecord({});

const preFetchedDataReducer: Reducer<PreFetchedDataState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actions.BOOT_SUCCEEDED:
    case actions.BOOT_FAILED:
      return state.merge(
        Map(action.preFetchedData)
          .filter((_value, key: string) => state.has(key))
          .map(
            (preFetchedRequest) =>
              new PreFetchedRequestRecord(preFetchedRequest),
          ),
      );
    default:
      return state;
  }
};

export default preFetchedDataReducer;
